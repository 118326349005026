@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@1,700;1,800&display=swap');
/*@font-face {
  font-family: 'Formula-One';
  src:  url('/fonts/Formula-One.otf') format('opentype');
}*/

.pneuVermelho{
  background-color: red; 
  vertical-align: middle!important;
  text-align: center!important;
}
.semBorda{
  border: 0px!important;
  padding: 1px!important;
  vertical-align: middle!important;
  text-align: center!important;
}

.tituloTabela {
	text-align: center!important;
  font-size: 14px;
}

.pneuSize{
  width: 70px!important;
  height: 70px!important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.bandeiraSize{
  width: 70px!important;
  height: 70px!important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.bandeiraPaisSize{
  width: 25px!important;
  height: 15px!important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.menuAlignSenhaAdministrativoNotificacao {
  padding-left: .5rem;
  padding-right: .5rem;
}
.margin10PxTop {
  margin-top: 10px
}
.margin90PxLeft {
  margin-left: 90px
}
@media screen and (max-width: 425px) {
  .margin90PxLeft {
    margin-left: 0px;
  }
}
.margin-50PxLeft {
  margin-left: -50px
}
.margin10PxLeft {
  margin-left: 10px
}
.slide-container {
  width: 30%;
  margin: auto;
}
.slide-container-side {
  width: 350px
}
.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 300px;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}
body {
	top: 0;
	background-image: url('Fundo.jpg'); 
  height: 100%;
}
.row-button{
  padding-top:34px;
  padding-bottom: 34px;
  padding-left: 10px;
  margin-right: 10px;
}
.black-color{
  background-color:black!important;
}
.padding15px {
  padding: 15px;
}
.paddingTopBottom10px {
  padding-top: 10px;
  padding-bottom: 10px;
}
.alignElementRight{
  margin-left: auto;
  display: block;
}
.IconSocialMediaNavBar {
  width: 30px;
  height: 30px;
}
.selectListHeight{
  height: 250px!important;
}
.navBarSocialMedia {
	margin-left: 30px;
	margin-right: 30px;
}
.wordStyle{
  font-family: 'Exo 2';
  font-weight:normal;
  font-size: 18px;
  color:white;
}
.wordStyleCalendario{
  font-family: 'Exo 2';
  font-weight:normal;
  color:white;
}
.cardPainelTexto{
  padding: 0px!important;
  border: 8px solid rgba(255,0,0)!important;
  border-radius: 3.25rem!important;
  background-color: black;
  color: white;
  min-width: fit-content;
}
.cardPainelTitulo{
  text-align: center;
  font-family: 'Exo 2', sans-serif;
  font-size: 36px;
  font-weight: 800;
}
.cardPainelSubTitulo{
  font-size: 20px;
  text-align: center;
  font-family: 'Exo 2', sans-serif;
  color: silver;
}
.whatsappIcon{
  display: inherit;
  margin-left: auto;
  margin-right: auto;
  width: 30px;
  height: 30px;
}
.fontePadrao{
  font-family: 'Exo 2', sans-serif;
  font-weight: 800;
}

.textAlignCenter{
  text-align: center;
}
.textoEmail{
  padding-left: 5px;
}
.texto_filtro_por {
  padding-right: 5px;
}
.allTemporadas {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: 'Exo 2', sans-serif;
  font-weight: 800;
}
.descricao-categoria{
  flex-direction: row!important;
  background-color: black;
}

.autorNoticiaInformacao {
  text-align: right;
}

.mensagemTriagemProfile{
    font-size: 18px;
    color: green;
    font-weight: bold;
}
.mensagemAguardandoTriagemProfile{
    font-size: 18px;
    color: red;
    font-weight: bold;
}

.img-tabela-pontuacao {
  height: 100%;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 85%; 
}

.imgCalendario {
  width: 350px;
}
.img-temporada{
  display: block;
  margin-left: auto;
  margin-right: auto;  
}
.img-categoria{
  display: block;
  margin-left: auto;
  margin-right: auto;
}
img {
  height: 100%;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.modal-dialog-campeonato {
  margin: 1.75rem auto!important;
}
.modal-dialog-noticias {
  max-width: 100%!important;
  margin: 1.75rem auto!important;
}
.close {
  color: red;
}
.modal-content {
  position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-clip: padding-box;
    
    border: 8px solid red;
    outline: 0;
    border-radius: 3.25rem!important;
    background-color: black;
}
.modalSize {
  width: 897px;
  height: 802px; 
}

.IconTable {
  width: 20px;
  height: 20px;
}
.centerTableImage {
  text-align: center;
}

.navMenu {
	max-width: 100%;
}

.navBarMargin {
	margin-top: 10px;
	margin-left: 30px;
	margin-right: 30px;
}
.navBarMarginSocialNetwork {
	margin-left: 30px;
	margin-right: 30px;
}
.backGroundColorVerde {
  background-color: #00FF00!important;
}
.backGroundColorVermelho {
  background-color:red!important;
}
.styleError{
  color: red!important;
  font-size: 15px;
}
.styleSuccess{
  color: green!important;
  font-size: 15px;
}
.styleTitle {
	color: #00f!important;
	font-size: 25px;
}
.style {
	font-size: 20px;
	color: #00f!important;
}
.menuBorder {
	border-width: medium;
	border-style: solid;
	border-color: #fff;
	background:rgba(0, 0, 0, 0) none repeat scroll 0 0!important;
	color: #00f!important;
}
.calendario {
	margin-top: 66px!important;
  margin-left: 30px!important;
  font-size: 30px!important;
  color: #fff!important;
}
.transparente {
	background:rgba(0, 0, 0, 0) none repeat scroll 0 0!important;
}
.nav-item nav-link {
	color: #007bff
}
.centered {
    float: none;
     margin-left: auto;
     margin-right: auto;
}
.tituloCalendario{
  text-align: center!important;
}
.tituloTemporada {
	text-align: center!important;
  font-family: 'Exo 2', sans-serif;
  font-size: 36px;
  font-weight: 800;
}
.tituloCategoria {
	text-align: center!important;
}
.width60porcento{
  width:60%!important;
}
.width500 {
	width: 500px;
	display: table-cell;
}
.width200 {
	width: 200px;
	display: table-cell;
}
.alignRight {
	text-align: right!important;
} 
.centerContent{
	margin-top: 110px!important;
}
/*.padding10px {
	padding: 10px!important;
}*/
.width50porcento{
  width: 50%;
  border-radius: 1.0rem!important;
}
.bordaArredondada{
  border-radius: 1.0rem!important;
}
.containerAccount{
  display: flex;
}
.fixed{
    width: 200px;
}
.flex-item{
    flex-grow: 1;
    margin-top: 0px!important;
    margin-left: 0px!important;
}
.dropdown-menu {
    position: absolute;
    width: auto;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0px;
    margin: .125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
}

#height10px{
  height: 10px;
}
.noPadding{
  padding: 0px!important;
}

.Side-menu {
  /*width: auto;
  width: 100%;*/
  max-width: 210px;
}
.Side-menu .children {
  transition: max-height 0.7s ease-in;
  overflow: hidden;
}
.Side-menu .children.active {
  transition-timing-function: cubic-bezier(0.5, 0, 1, 0);
  max-height: 9999px;
}
.Side-menu .children.inactive {
  transition: max-height 0.6s cubic-bezier(0, 1, 0, 1) -0.15s;
  max-height: 0;
}
.Side-menu * {
  box-sizing: border-box;
}
.Side-menu .divider {
  height: 42px;
  padding-top: 14px;
  padding-left: 14px;
  padding-right: 14px;
  font-size: 12px;
}
.Side-menu.rtl .divider {
  text-align: right;
}
.Side-menu.rtl .item .item-title .item-label {
  float: right;
}
.Side-menu.rtl .item .item-title .item-icon {
  margin-right: 0px;
  margin-left: 10px;
  margin-top: 3px;
  float: right;
}
.Side-menu.rtl .item .item-title .fa-chevron-down,
.Side-menu.rtl .item .item-title .fa-chevron-left,
.Side-menu.rtl .item .item-title .fa-chevron-right {
  float: left;
}
.Side-menu.rtl .item .children {
  padding-left: 0;
  padding-right: 25px;
}
.Side-menu .item .item-title {
  height: 40px;
  padding-top: 14px;
  padding-left: 18px;
  padding-right: 18px;
  font-size: 14px;
  cursor: pointer !important;
}
.Side-menu .item .item-title .item-icon {
  margin-right: 10px;
}
.Side-menu .item .item-title .fa-chevron-down,
.Side-menu .item .item-title .fa-chevron-left,
.Side-menu .item .item-title .fa-chevron-right {
  float: right;
  position: relative;
  font-size: 12px;
  padding-top: 3px;
}
.Side-menu .item:hover > .item-title {
  cursor: pointer;
}
.Side-menu .item.item-level-1 > .item-title {
  height: 45px;
}
.Side-menu .item .children {
  padding-left: 25px;
}
.Side-menu-default {
  background-color: #222D32;
  color: #B8C7CE;
}
.Side-menu-default *:not(i) {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300;
}
.Side-menu-default .divider {
  background-color: #1A2226;
  color: #4A636E;
  text-transform: uppercase;
}
.Side-menu-default .item a {
  text-decoration: none;
  color: #B8C7CE;
}
.Side-menu-default .item.active {
  color: white;
}
.Side-menu-default .item.active .children {
  color: #B8C7CE;
}
.Side-menu-default .item.active > .item-title > a {
  color: white;
}
.Side-menu-default .item:hover > .item-title {
  color: white;
}
.Side-menu-default .item:hover > .item-title a {
  color: white;
}
.Side-menu-default .item.item-level-1:hover > .item-title {
  background-color: #435863;
}
.Side-menu-default .item.item-level-1:hover,
.Side-menu-default .item.item-level-1.active {
  border-left: 4px solid #C4EAF2;
}
.quadrado_250{
  width: 250px;
  height: 250px;
}
